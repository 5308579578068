.pagination__indicator {
    display: inline-flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
    padding: 1rem;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #a1a1b7;
}
.pagination__indicator:hover {
    background: #a1a1b7;
    color: white;
}

@media (max-width: 767px) {
    .pagination__indicator {
        position: relative;
        left: calc(100% - 100px);
        margin: 1rem 0 1rem 0;
    }
}