.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000e1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ping {
    animation: ping 1.5s ease-in-out infinite both;
}
@keyframes ping {
    0% {
        transform: scale(0.2);
        /*opacity: 1;*/
        box-shadow: 0 0 0 0 #007BFF16;
    }
    80% {
        transform: scale(.7);
        /*opacity: 0.1;*/
        box-shadow: 0 0 0 .3rem #007BFF16;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
        box-shadow: 0 0 0 .5rem #007BFF16;
    }
}
